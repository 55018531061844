import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <hr></hr>
    <p><strong parentName="p">{`Usable with CoreMedia Content Cloud - Service`}</strong></p>
    <hr></hr>
    <p>{`The CoreMedia Event Hub Service is a cloud-only service which enables you to subscribe for the events of CoreMedia Content
Cloud. An event is created whenever a content item has been changed, created and deleted on either the Content Management
Server or the Master Live Server. Additionally, certain user actions in the CoreMedia Studio and changes in workflows can trigger events.`}</p>
    <h2>{`Getting Access to the Event Hub Service`}</h2>
    <p>{`Contact the CoreMedia support at `}<a parentName="p" {...{
        "href": "mailto:support@coremediaoncloud.com"
      }}>{`support@coremediaoncloud.com`}</a>{` to get the Event Hub Service activated for your account.`}</p>
    <p>{`The CoreMedia support will provide you with the URL of the subscription service and will provide secrets in the Cloud Manager
of your instance. Use the secrets to get the token, necessary to access the subscription service.`}</p>
    <h2>{`Resources`}</h2>
    <ul>
      <li parentName="ul">{`Get an introduction into the usage of the Event Hub in `}<a parentName="li" {...{
          "href": "https://documentation.coremedia.com/coremedia-services/event-hub-service/introduction-to-event-hub/"
        }}>{`Introduction into Event Hub Service`}</a></li>
      <li parentName="ul">{`Get an introduction into the usage of the Event Hub Subscription Service in `}<a parentName="li" {...{
          "href": "https://documentation.coremedia.com/coremedia-services/event-hub-service/how-to-event-hub-subscription/"
        }}>{`Using the Event Hub Subscription Service`}</a></li>
      <li parentName="ul">{`Get an introduction into filtering events with the Event Hub Service  in `}<a parentName="li" {...{
          "href": "https://documentation.coremedia.com/coremedia-services/event-hub-service/event-hub-filtering/"
        }}>{`Filtering events with the Event Hub Service`}</a></li>
      <li parentName="ul">{`Find the Event Hub API at `}<a parentName="li" {...{
          "href": "https://documentation.coremedia.com/eventhub-api/"
        }}>{`Event Hub API`}</a></li>
      <li parentName="ul">{`Find the Event Hub Subscription Service API at `}<a parentName="li" {...{
          "href": "https://documentation.coremedia.com/eventdeliveryconfig-api/"
        }}>{`Event Hub Subscription Service API`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      